@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200..900&display=swap');
html,
body,
#root,
.app,
.content{
    overflow: auto;
    height:100%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}
.app{
    display: flex;
    position: relative;
}

::-webkit-scrollbar{
    width: 8px;
}

::-webkit-scrollbar-track{
    background: #beb2cb;
}

::-webkit-scrollbar-thumb{
    background: #463473;
}
::-webkit-scrollbar-thumb:hover{
    background: #2a1f45;
}

li.pro-menu-item{
    margin-top: 5px !important;
}